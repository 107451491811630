import React from 'react';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import team1Img from '../assets/images/DR_BV_Ra.jpg';
import team2Img from '../assets/images/team-2.jpg';
import team3Img from '../assets/images/DR_HS_Jayaprakash (2).jpg';
import { Link } from 'react-router-dom';

const OwlCarousel = require('react-owl-carousel3').default;

const Team: React.FC = () => {
  const teamMembers = [
    {
      name: 'Dr. B V Raghunandan',
      specialty: 'Consultant General Surgeon',
      description:
        'Dr. B V Raghunandan is a highly qualified Consultant General Surgeon based in Rajaji Nagar, Bangalore, currently practicing at PMC Hospital.',
      image: team1Img,
      routeName: 'b-v-raghunandan',
    },
    {
      name: 'Dr. B V R Bhat',
      specialty: 'Consultant General Physician',
      description:
        'Dr. B V R Bhat is currently practicing at PMC Hospital, where he provides comprehensive medical care to patients from across Rajaji Nagar,Bangalore.',
      image: team2Img,
      routeName: 'b-v-r-bhat',
    },
    {
      name: 'Dr. V. K. Bellubbi',
      specialty: 'Consultant General Physician',
      description:
        'Dr. V. K. Bellubbi is a highly experienced General Physician based in Rajaji Nagar, Bangalore, currently practicing at PMC Hospital.',
      image: team2Img,
      routeName: 'v-k-bellubbi',
    },
    {
      name: 'Dr. H S Jayaprakash',
      specialty: 'Consultant General Physician',
      description:
        'Dr. H S Jayaprakash is an experienced General Physician based in Rajaji Nagar, Bangalore, currently practicing at PMC Hospital.',
      image: team3Img,
      routeName: 'h-s-jayaprakash',
    },
  ];

  const options = {
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    navText: ['&#10094;', '&#10095;'],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <div className="container-fluid py-5">
      <div className="container">
        <div className="text-center mx-auto mb-5" style={{ maxWidth: 500 }}>
          <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">
            Our Doctors
          </h5>
          <h1 className="display-4">Qualified Healthcare Professionals</h1>
        </div>

        <OwlCarousel
          className="owl-carousel team-carousel position-relative"
          {...options}
        >
          {teamMembers.map((member, index) => (
            <div className="team-item" key={index}>
              <div className="row g-0 bg-light rounded overflow-hidden">
                <div className="col-12 col-sm-5 h-100">
                  <a href="#" target="_blank" rel="noopener noreferrer">
                    <img
                      className="img-fluid h-100"
                      src={member.image}
                      alt={member.name}
                      style={{ objectFit: 'cover' }}
                    />
                  </a>
                </div>
                <div className="col-12 col-sm-7 h-100 d-flex flex-column">
                  <div className="mt-auto p-4">
                    <h3>{member.name}</h3>
                    <h6 className="fw-normal fst-italic text-primary mb-4">
                      {member.specialty}
                    </h6>
                    <p className="m-0">{member.description}</p>
                  </div>
                  <div className="d-flex mt-auto border-top moreLink">
                    <a
                      className="btn btn-lg btn-lg-square me-3 moreLinkAnchor"
                      href={`/doctor/${member.routeName}`}
                      target="_blank"
                    >
                      More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default Team;
