import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
// import Pricing from './pages/Pricing';
// import Blog from './pages/Blog';
// import BlogDetail from './pages/BlogDetail';
import Team from './pages/Team';
import Footer from './components/Footer';
import Contact from './pages/Contact';
import DoctorProfile from './components/DoctorProfile';
import HospitalAppointmentForm from './components/AppointmentForm';
// import Testimonial from './pages/Testimonial';
// import Appointment from './pages/Appointment';
// import Search from './pages/Search';

const App: React.FC = () => {
  const location = useLocation();

  const isDoctorProfile = location.pathname.startsWith('/doctor/');
  return (
    <>
      {!isDoctorProfile && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/appointment" element={<HospitalAppointmentForm />} />
        {/*<Route path="/price" element={<Pricing />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/detail" element={<BlogDetail />} />
        <Route path="/testimonial" element={<Testimonial />} />
        <Route path="/appointment" element={<Appointment />} />
        <Route path="/search" element={<Search />} />
        <Route path="/contact" element={<Contact />} /> */}
        <Route path="/doctor/:doctorName" element={<DoctorProfile />} />
      </Routes>
      {!isDoctorProfile && <Footer />}
    </>
  );
};

const AppWrapper: React.FC = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
