import React, { useState } from 'react';

type Errors = {
  name?: string;
  mobile?: string;
  email?: string;
  appointmentType?: string;
  selectedOption?: string;
  selectedDate?: string;
  selectedTime?: string;
};

const HospitalAppointmentForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    appointmentType: '',
    selectedOption: '',
    selectedDate: '',
    selectedTime: '',
  });
  const [errors, setErrors] = useState<Errors>({});
  const [disabledSlots, setDisabledSlots] = useState<string[]>([]);

  const labOptions = ['Blood Test', 'X-Ray', 'MRI'];
  const doctorOptions = [
    'B V Raghunandan',
    'B V R Bhat',
    'V K Bellubi',
    'H S Jayaprakash',
    'Vijayalakshmi Bhat',
    'Mrutyunjaya',
    'Muralidhara Rao',
    'Prabhakar Rao',
  ];
  const timeSlots = [
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
  ];

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (name === 'name' && value.trim() !== '') {
        delete updatedErrors.name;
      }
      if (name === 'mobile') {
        if (/^\d{10}$/.test(value)) {
          delete updatedErrors.mobile;
        }
      }
      if (name === 'email') {
        if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
          delete updatedErrors.email;
        } else {
          updatedErrors.email = 'Enter a valid email address.';
        }
      }
      if (name === 'appointmentType' && value !== '') {
        delete updatedErrors.appointmentType;
      }
      if (name === 'selectedOption' && value !== '') {
        delete updatedErrors.selectedOption;
      }
      if (name === 'selectedDate' && value !== '') {
        delete updatedErrors.selectedDate;
      }
      return updatedErrors;
    });
  };

  const handleTimeChange = (slot: string) => {
    setFormData({ ...formData, selectedTime: slot });
    setDisabledSlots((prev) => [...prev, slot]);
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.selectedTime;
      return updatedErrors;
    });
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.name) newErrors.name = 'Name is required.';
    if (!formData.mobile) newErrors.mobile = 'Mobile number is required.';
    if (!/^\d{10}$/.test(formData.mobile))
      newErrors.mobile = 'Enter a valid 10-digit mobile number.';
    if (!formData.email) {
      newErrors.email = 'Email is required.';
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
    ) {
      newErrors.email = 'Enter a valid email address.';
    }
    if (!formData.appointmentType)
      newErrors.appointmentType = 'Appointment type is required.';
    if (!formData.selectedOption)
      newErrors.selectedOption = 'Option is required.';
    if (!formData.selectedDate) newErrors.selectedDate = 'Date is required.';
    if (!formData.selectedTime)
      newErrors.selectedTime = 'Time slot is required.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      // Send data to the backend for email sending
      const response = await fetch('https://www.vshealth.in/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Appointment booked and email sent successfully!');
      } else {
        alert('Failed to send email. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className="container-fluid pt-5">
      <div className="container">
        <div className="text-center mx-auto" style={{ maxWidth: '500px' }}>
          <h1 className="display-4">Appointment Form</h1>
        </div>
        <div
          className="row justify-content-center position-relative"
          style={{ zIndex: 1 }}
        >
          <div className="col-lg-8">
            <div className="bg-white rounded p-5 m-5 mb-0">
              <form onSubmit={handleSubmit}>
                <div className="row g-3">
                  <div className="col-12 col-sm-6">
                    <label>Name:</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control bg-light border-0"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <p style={{ color: 'red' }}>{errors.name}</p>
                    )}
                  </div>
                  <div className="col-12 col-sm-6">
                    <label>Mobile Number:</label>
                    <input
                      type="text"
                      name="mobile"
                      className="form-control bg-light border-0"
                      value={formData.mobile}
                      onChange={handleChange}
                    />
                    {errors.mobile && (
                      <p style={{ color: 'red' }}>{errors.mobile}</p>
                    )}
                  </div>
                  <div className="col-12">
                    <label>Email:</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control bg-light border-0"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <p style={{ color: 'red' }}>{errors.email}</p>
                    )}
                  </div>
                  <div className="col-12">
                    <label>Appointment Type:</label>
                    <select
                      name="appointmentType"
                      className="form-control bg-light border-0"
                      value={formData.appointmentType}
                      onChange={handleChange}
                    >
                      <option value="">Select Appointment Type</option>
                      <option value="lab">Lab Appointment</option>
                      <option value="doctor">Doctor Appointment</option>
                    </select>
                    {errors.appointmentType && (
                      <p style={{ color: 'red' }}>{errors.appointmentType}</p>
                    )}
                  </div>
                  {/* Dynamic Options */}
                  {formData.appointmentType && (
                    <div>
                      <label>Type:</label>
                      <select
                        className="form-control bg-light border-0"
                        name="selectedOption"
                        value={formData.selectedOption}
                        onChange={handleChange}
                      >
                        <option value="">Select Type</option>
                        {(formData.appointmentType === 'lab'
                          ? labOptions
                          : doctorOptions
                        ).map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      {errors.selectedOption && (
                        <p style={{ color: 'red' }}>{errors.selectedOption}</p>
                      )}
                    </div>
                  )}
                  <div className="col-12">
                    <label>Date:</label>
                    <input
                      type="date"
                      name="selectedDate"
                      className="form-control bg-light border-0"
                      value={formData.selectedDate}
                      min={new Date().toISOString().split('T')[0]}
                      onChange={handleChange}
                    />
                    {errors.selectedDate && (
                      <p style={{ color: 'red' }}>{errors.selectedDate}</p>
                    )}
                  </div>
                  {/* Time Slots */}
                  {formData.selectedDate && (
                    <div>
                      <label>Time Slots:</label>
                      <div>
                        {timeSlots.map((slot) => (
                          <button
                            key={slot}
                            type="button"
                            onClick={() => handleTimeChange(slot)}
                            disabled={disabledSlots.includes(slot)}
                            style={{
                              margin: '5px',
                              padding: '10px',
                              backgroundColor: disabledSlots.includes(slot)
                                ? 'gray'
                                : 'lightblue',
                            }}
                          >
                            {slot}
                          </button>
                        ))}
                      </div>
                      {errors.selectedTime && (
                        <p style={{ color: 'red' }}>{errors.selectedTime}</p>
                      )}
                    </div>
                  )}
                  <div className="col-12">
                    <button
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                    >
                      Book appointment
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HospitalAppointmentForm;
