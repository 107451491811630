import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import '../assets/css/componentStyles.css';

type DoctorDetails = {
  name: string;
  title: string;
  location: string;
  hospital: string;
  about: string;
  expertise: string[];
  patientCare: string;
  appointment: string;
};

const doctorDetails: Record<string, DoctorDetails> = {
  'v-k-bellubbi': {
    name: 'Dr. V. K. Bellubbi',
    title: 'Consultant General Physician',
    location: 'Rajaji Nagar, Bangalore',
    hospital: 'PMC Hospital, Rajaji Nagar, Bangalore',
    about:
      'Dr. V. K. Bellubbi is a highly experienced General Physician based in Rajaji Nagar, Bangalore.',
    expertise: [
      'General Practitioner',
      'Internal Medicine',
      'Emergency Medicine',
      'General Health Checkups',
      'Treatment for Chronic Illnesses',
      'Preventive Care & Vaccinations',
      'Diagnosis and Management of Acute and Chronic Conditions',
    ],
    patientCare:
      'Committed to ensuring that each patient feels heard and well-cared for.',
    appointment: 'Available by appointment',
  },
  'b-v-raghunandan': {
    name: 'Dr. B V Raghunandan',
    title: 'Consultant General Surgeon',
    location: 'Rajaji Nagar, Bangalore',
    hospital: 'PMC Hospital, Rajaji Nagar, Bangalore',
    about:
      'Dr. B V Raghunandan is a highly qualified and experienced consultant general surgeon based in Rajaji Nagar, Bangalore. With a focus on patient-centered care, he has earned the trust of numerous patients throughout Rajaji Nagar and the broader Bangalore area.',
    expertise: [
      'Bariatric Surgery',
      'General Surgery',
      'Laparoscopic Surgery',
      'Gastric Internal Surgery',
      'Gallbladder Surgery',
      'Breast Surgery',
      'Piles Surgery',
      'Hernia Surgery',
    ],
    patientCare:
      'Dr. Raghunandan employs advanced techniques and his extensive knowledge to provide effective surgical solutions tailored to each patient’s needs. He is dedicated to ensuring that his patients feel informed, comfortable, and satisfied with their treatment journeys. Whether you’re seeking routine surgery or specialized care, Dr. B V Raghunandan is committed to delivering the highest standard of surgical excellence.',
    appointment: 'Available by appointment',
  },
  'b-v-r-bhat': {
    name: 'Dr. B V R Bhat',
    title: 'Consultant General Physician',
    location: 'Rajaji Nagar, Bangalore',
    hospital: 'PMC Hospital, Rajaji Nagar, Bangalore',
    about:
      'Dr. B V R Bhat offers a wide range of medical services. His areas of expertise include general medical consultations, diagnosis and treatment of acute and chronic illnesses, and emergency care.',
    expertise: [
      'General Practitioner',
      'Internal Medicine',
      'Emergency Medicine',
      'General Health Checkups',
      'Treatment for Chronic Illnesses',
      'Preventive Care & Vaccinations',
      'Diagnosis and Management of Acute and Chronic Conditions',
    ],
    patientCare:
      'Patients trust Dr. B V R Bhat for his patient-centered approach and commitment to providing high-quality healthcare. His experience and effective treatment methods ensure that each patient receives personalized care and achieves optimal health outcomes.',
    appointment: 'Available by appointment',
  },
  'h-s-jayaprakash': {
    name: 'Dr. H. S. Jayaprakash',
    title: 'Consultant General Physician',
    location: 'Rajaji Nagar, Bangalore',
    hospital: 'PMC Hospital, Rajaji Nagar, Bangalore',
    about:
      'Dr. Jayaprakash specializes in a wide range of medical services, including internal medicine, general healthcare, and emergency care.',
    expertise: [
      'General Practitioner',
      'Internal Medicine',
      'Emergency Medicine',
      'General Health Checkups',
      'Treatment for Chronic Illnesses',
      'Preventive Care & Vaccinations',
      'Diagnosis and Management of Acute and Chronic Conditions',
    ],
    patientCare:
      'Dr. Jayaprakash commitment to patient satisfaction and his use of modern medical techniques have earned him the trust of the community.',
    appointment: 'Available by appointment',
  },
  // 'v-k-bellubbi': {
  //   name: 'Dr. V. K. Bellubbi',
  //   title: 'Consultant General Physician',
  //   location: 'Rajaji Nagar, Bangalore',
  //   hospital: 'PMC Hospital, Rajaji Nagar, Bangalore',
  //   about:
  //     'Dr. V. K. Bellubbi is a highly experienced General Physician based in Rajaji Nagar, Bangalore...',
  //   expertise: [
  //     'General Practitioner',
  //     'Internal Medicine',
  //     'Emergency Medicine',
  //     'General Health Checkups',
  //     'Treatment for Chronic Illnesses',
  //     'Preventive Care & Vaccinations',
  //     'Diagnosis and Management of Acute and Chronic Conditions',
  //   ],
  //   patientCare:
  //     'Committed to ensuring that each patient feels heard and well-cared for...',
  //   appointment: 'Available by appointment',
  // },
  // 'v-k-bellubbi': {
  //   name: 'Dr. V. K. Bellubbi',
  //   title: 'Consultant General Physician',
  //   location: 'Rajaji Nagar, Bangalore',
  //   hospital: 'PMC Hospital, Rajaji Nagar, Bangalore',
  //   about:
  //     'Dr. V. K. Bellubbi is a highly experienced General Physician based in Rajaji Nagar, Bangalore...',
  //   expertise: [
  //     'General Practitioner',
  //     'Internal Medicine',
  //     'Emergency Medicine',
  //     'General Health Checkups',
  //     'Treatment for Chronic Illnesses',
  //     'Preventive Care & Vaccinations',
  //     'Diagnosis and Management of Acute and Chronic Conditions',
  //   ],
  //   patientCare:
  //     'Committed to ensuring that each patient feels heard and well-cared for...',
  //   appointment: 'Available by appointment',
  // },
  // 'v-k-bellubbi': {
  //   name: 'Dr. V. K. Bellubbi',
  //   title: 'Consultant General Physician',
  //   location: 'Rajaji Nagar, Bangalore',
  //   hospital: 'PMC Hospital, Rajaji Nagar, Bangalore',
  //   about:
  //     'Dr. V. K. Bellubbi is a highly experienced General Physician based in Rajaji Nagar, Bangalore...',
  //   expertise: [
  //     'General Practitioner',
  //     'Internal Medicine',
  //     'Emergency Medicine',
  //     'General Health Checkups',
  //     'Treatment for Chronic Illnesses',
  //     'Preventive Care & Vaccinations',
  //     'Diagnosis and Management of Acute and Chronic Conditions',
  //   ],
  //   patientCare:
  //     'Committed to ensuring that each patient feels heard and well-cared for...',
  //   appointment: 'Available by appointment',
  // },
  // 'v-k-bellubbi': {
  //   name: 'Dr. V. K. Bellubbi',
  //   title: 'Consultant General Physician',
  //   location: 'Rajaji Nagar, Bangalore',
  //   hospital: 'PMC Hospital, Rajaji Nagar, Bangalore',
  //   about:
  //     'Dr. V. K. Bellubbi is a highly experienced General Physician based in Rajaji Nagar, Bangalore...',
  //   expertise: [
  //     'General Practitioner',
  //     'Internal Medicine',
  //     'Emergency Medicine',
  //     'General Health Checkups',
  //     'Treatment for Chronic Illnesses',
  //     'Preventive Care & Vaccinations',
  //     'Diagnosis and Management of Acute and Chronic Conditions',
  //   ],
  //   patientCare:
  //     'Committed to ensuring that each patient feels heard and well-cared for...',
  //   appointment: 'Available by appointment',
  // },
  // 'v-k-bellubbi': {
  //   name: 'Dr. V. K. Bellubbi',
  //   title: 'Consultant General Physician',
  //   location: 'Rajaji Nagar, Bangalore',
  //   hospital: 'PMC Hospital, Rajaji Nagar, Bangalore',
  //   about:
  //     'Dr. V. K. Bellubbi is a highly experienced General Physician based in Rajaji Nagar, Bangalore...',
  //   expertise: [
  //     'General Practitioner',
  //     'Internal Medicine',
  //     'Emergency Medicine',
  //     'General Health Checkups',
  //     'Treatment for Chronic Illnesses',
  //     'Preventive Care & Vaccinations',
  //     'Diagnosis and Management of Acute and Chronic Conditions',
  //   ],
  //   patientCare:
  //     'Committed to ensuring that each patient feels heard and well-cared for...',
  //   appointment: 'Available by appointment',
  // },
};

const DoctorProfile: React.FC = () => {
  const { doctorName } = useParams<{ doctorName?: string }>(); // doctorName is optional now
  const doctor = doctorName ? doctorDetails[doctorName] : undefined;

  const navigate = useNavigate();

  if (!doctor) return <div>Doctor profile not found</div>;

  return (
    <div className="container-fluid py-5">
      <div className="doctorContainer">
        <div className="container">
          <h1>{doctor.name}</h1>
          <h2 className="text-primary">{doctor.title}</h2>
          <p>
            <strong>Location:</strong> {doctor.location}
          </p>
          <h3>About {doctor.name}</h3>
          <p>{doctor.about}</p>
          <h4>Areas of Expertise</h4>
          <ul>
            {doctor.expertise.map((area, index) => (
              <li key={index}>{area}</li>
            ))}
          </ul>
          <h4>Patient-Centered Care</h4>
          <p>{doctor.patientCare}</p>
          <h4>Visit {doctor.name}</h4>
          <p>
            <strong>Hospital:</strong> {doctor.hospital}
          </p>
          <p>
            <strong>Consultation:</strong> {doctor.appointment}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DoctorProfile;
