import React from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import logoImg from '../assets/images/logo.jpg';

const Header: React.FC = () => {
  return (
    <div className="container-fluid sticky-top bg-white shadow-sm">
      <div className="container">
        <nav className="navbar navbar-expand-lg bg-white navbar-light py-3 py-lg-0">
          <Link to="/" className="navbar-brand">
            <h1 className="m-0 text-uppercase text-primary">
              <i className="fa fa-clinic-medical me-2"></i>VS HEALTH CARE
            </h1>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
              <NavLink
                to="/"
                end
                className={({ isActive }) =>
                  `nav-item nav-link ${isActive ? 'active' : ''}`
                }
              >
                Home
              </NavLink>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  `nav-item nav-link ${isActive ? 'active' : ''}`
                }
              >
                About
              </NavLink>
              <NavLink
                to="/services"
                className={({ isActive }) =>
                  `nav-item nav-link ${isActive ? 'active' : ''}`
                }
              >
                Services
              </NavLink>
              <NavLink
                to="/team"
                className={({ isActive }) =>
                  `nav-item nav-link ${isActive ? 'active' : ''}`
                }
              >
                Team
              </NavLink>
              {/* <Link to="/price" className="nav-item nav-link">
                Pricing
              </Link> */}
              {/* <div className="nav-item dropdown">
                <a
                  href="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Pages
                </a>
                <div className="dropdown-menu m-0">
                  <Link to="/blog" className="dropdown-item">
                    Blog Grid
                  </Link>
                  <Link to="/detail" className="dropdown-item">
                    Blog Detail
                  </Link>
                  <Link to="/team" className="dropdown-item">
                    The Team
                  </Link>
                  <Link to="/testimonial" className="dropdown-item">
                    Testimonial
                  </Link>
                  <Link to="/appointment" className="dropdown-item">
                    Appointment
                  </Link>
                  <Link to="/search" className="dropdown-item">
                    Search
                  </Link>
                </div>
              </div> */}
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  `nav-item nav-link ${isActive ? 'active' : ''}`
                }
              >
                Contact
              </NavLink>
              <Link
                to="/appointment"
                className="btn btn-primary w-100 appointmentBtn"
              >
                Book Appointment
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
