import React from 'react';

const Contact: React.FC = () => {
  return (
    <div className="container-fluid pt-5">
      <div className="container">
        <div className="text-center mx-auto mb-5" style={{ maxWidth: '500px' }}>
          <h1 className="display-4">Please Feel Free To Contact Us</h1>
        </div>

        <div className="row g-5 mb-5">
          <div className="col-lg-4">
            <div
              className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
              style={{ height: '200px' }}
            >
              <div
                className="d-flex align-items-center justify-content-center bg-primary rounded-circle mb-4"
                style={{
                  width: '100px',
                  height: '70px',
                  transform: 'rotate(-15deg)',
                }}
              >
                <i
                  className="fa fa-2x fa-location-arrow text-white"
                  style={{ transform: 'rotate(15deg)' }}
                ></i>
              </div>
              <h6 className="mb-0">
                539/A, Dr Rajkumar Rd, A Block, Milk Colony, 2nd Stage,
                Rajajinagar, Bengaluru, Karnataka 560010
              </h6>
            </div>
          </div>

          <div className="col-lg-4">
            <div
              className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
              style={{ height: '200px' }}
            >
              <div
                className="d-flex align-items-center justify-content-center bg-primary rounded-circle mb-4"
                style={{
                  width: '100px',
                  height: '70px',
                  transform: 'rotate(-15deg)',
                }}
              >
                <i
                  className="fa fa-2x fa-phone text-white"
                  style={{ transform: 'rotate(15deg)' }}
                ></i>
              </div>
              <h6 className="mb-0">080 2337 1633</h6>
            </div>
          </div>

          <div className="col-lg-4">
            <div
              className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
              style={{ height: '200px' }}
            >
              <div
                className="d-flex align-items-center justify-content-center bg-primary rounded-circle mb-4"
                style={{
                  width: '100px',
                  height: '70px',
                  transform: 'rotate(-15deg)',
                }}
              >
                <i
                  className="fa fa-2x fa-envelope-open text-white"
                  style={{ transform: 'rotate(15deg)' }}
                ></i>
              </div>
              <h6 className="mb-0">contact@vshealth.in</h6>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12" style={{ height: '500px' }}>
            <div className="position-relative h-100">
              {/* <iframe
                className="position-relative w-100 h-100"
                src="https://www.google.com/maps/place/PMC+Hospital/@13.006462,77.5519111,17z/data=!4m14!1m7!3m6!1s0x3bae3d83beb1eb73:0xad9c48b7456d3ef!2sPMC+Hospital!8m2!3d13.006462!4d77.554486!16s%2Fg%2F1tmckp33!3m5!1s0x3bae3d83beb1eb73:0xad9c48b7456d3ef!8m2!3d13.006462!4d77.554486!16s%2Fg%2F1tmckp33?entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen
                aria-hidden="false"
                tabIndex={0}
              ></iframe> */}
              <iframe
                className="position-relative w-100 h-100"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11898.344551268301!2d77.53915387554788!3d13.011115081312788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d83beb1eb73%3A0xad9c48b7456d3ef!2sPMC%20Hospital!5e0!3m2!1sen!2sin!4v1730563790481!5m2!1sen!2sin"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                aria-hidden="false"
                tabIndex={0}
              ></iframe>
            </div>
          </div>
        </div>

        <div
          className="row justify-content-center position-relative"
          style={{ marginTop: '-200px', zIndex: 1 }}
        >
          <div className="col-lg-8">
            <div className="bg-white rounded p-5 m-5 mb-0">
              <form>
                <div className="row g-3">
                  <div className="col-12 col-sm-6">
                    <input
                      type="text"
                      className="form-control bg-light border-0"
                      placeholder="Your Name"
                      style={{ height: '55px' }}
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <input
                      type="email"
                      className="form-control bg-light border-0"
                      placeholder="Your Email"
                      style={{ height: '55px' }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control bg-light border-0"
                      placeholder="Subject"
                      style={{ height: '55px' }}
                    />
                  </div>
                  <div className="col-12">
                    <textarea
                      className="form-control bg-light border-0"
                      rows={5}
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
