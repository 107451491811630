import React from 'react';
import aboutImage from '../assets/images/about.jpg';

const About: React.FC = () => {
  return (
    <div className="container-fluid py-5">
      <div className="container">
        <div className="row gx-5">
          <div className="col-lg-5 mb-5 mb-lg-0" style={{ minHeight: '500px' }}>
            <div className="position-relative h-100">
              <img
                className="position-absolute w-100 h-100 rounded"
                src={aboutImage}
                alt="About Us"
                style={{ objectFit: 'cover' }}
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="mb-4">
              <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">
                About Us
              </h5>
              <h1 className="display-4">
                Best Medical Care For Yourself and Your Family
              </h1>
            </div>
            <p>
              <strong>Primary Medicare Centre</strong> was founded in the late
              1970s by two visionary doctors who shared a passion for delivering
              quality healthcare. Starting with a small house on Rajkumar Rasta,
              they transformed it into a modest medical center with the help of
              like-minded friends and family who were also in the medical field.
              Brick by brick, this dedicated team built a fully-fledged
              multi-specialty hospital, complete with a pharmacy and laboratory.
            </p>
            <p>
              For the local community,{' '}
              <strong>
                Primary Medicare Centre became a trusted and affordable place
                for emergencies, inpatient care, and routine health needs.
              </strong>{' '}
              Over the years, the hospital expanded to include a modern and
              hygienic operating room, which later grew to two operating rooms
              and a dedicated labor room. The center gained a reputation for its
              patient-centered care, delivered by dynamic doctors who were both
              general practitioners and specialists. The entire staff, from
              nurses to doctors, fostered a familial atmosphere, creating a
              warm, supportive environment for both patients and employees.
            </p>
            <p>
              As the hospital grew, so did its reputation for compassionate,
              precise diagnostic and rehabilitative services. However, following
              the COVID-19 pandemic and the retirement of several long-serving
              doctors, the hospital transitioned to focusing solely on
              outpatient services. Today, two doctors, related and close friends
              of the original founders, have taken over the administration,
              continuing the legacy of excellence and patient-centered care that
              has defined <strong>Primary Medicare Centre</strong> for decades.
            </p>

            <div className="row g-3 pt-3">
              <div className="col-sm-3 col-6">
                <div className="bg-light text-center rounded-circle py-4">
                  <i className="fa fa-3x fa-user-md text-primary mb-3"></i>
                  <h6 className="mb-0">
                    Qualified
                    <small className="d-block text-primary">Doctors</small>
                  </h6>
                </div>
              </div>
              <div className="col-sm-3 col-6">
                <div className="bg-light text-center rounded-circle py-4">
                  <i className="fa fa-3x fa-procedures text-primary mb-3"></i>
                  <h6 className="mb-0">
                    Emergency
                    <small className="d-block text-primary">Services</small>
                  </h6>
                </div>
              </div>
              <div className="col-sm-3 col-6">
                <div className="bg-light text-center rounded-circle py-4">
                  <i className="fa fa-3x fa-microscope text-primary mb-3"></i>
                  <h6 className="mb-0">
                    Accurate
                    <small className="d-block text-primary">Testing</small>
                  </h6>
                </div>
              </div>
              {/* <div className="col-sm-3 col-6">
                <div className="bg-light text-center rounded-circle py-4">
                  <i className="fa fa-3x fa-ambulance text-primary mb-3"></i>
                  <h6 className="mb-0">
                    Free
                    <small className="d-block text-primary">Ambulance</small>
                  </h6>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
