import React from 'react';
import heroImage from '../assets/images/hero.jpg';
import About from './About';
import Services from './Services';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import homeImg from '../assets/images/Hospital.jpg';
import team1Img from '../assets/images/DR_BV_Ra.jpg';
import team2Img from '../assets/images/Bellubbi.jpg';
import team3Img from '../assets/images/DR_HS_Jayaprakash (2).jpg';
import { Link } from 'react-router-dom';

const Home: React.FC = () => {
  const teamMembers = [
    {
      name: 'Dr. B V Raghunandan',
      specialty: 'Consultant General Surgeon',
      description:
        'Dr. B V Raghunandan is a highly qualified Consultant General Surgeon based in Rajaji Nagar, Bangalore, currently practicing at PMC Hospital.',
      image: team1Img,
      routeName: 'b-v-raghunandan',
    },
    {
      name: 'Dr. V. K. Bellubbi',
      specialty: 'Consultant General Physician',
      description:
        'Dr. V. K. Bellubbi is a highly experienced General Physician based in Rajaji Nagar, Bangalore, currently practicing at PMC Hospital.',
      image: team2Img,
      routeName: 'v-k-bellubbi',
    },
    {
      name: 'Dr. H S Jayaprakash',
      specialty: 'Consultant General Physician',
      description:
        'Dr. H S Jayaprakash is an experienced General Physician based in Rajaji Nagar, Bangalore, currently practicing at PMC Hospital.',
      image: team3Img,
      routeName: 'h-s-jayaprakash',
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div
        className="container-fluid py-5 mb-5 hero-header mainImg"
        style={{
          background: `url(${homeImg}) top/70% no-repeat`,
          backgroundPosition: 'top',
        }}
      >
        <div className="container py-5 textOnImg">
          <div className="row justify-content-start">
            <div className="col-lg-8 text-center text-lg-start">
              <h5
                className="d-inline-block text-uppercase border-bottom border-5"
                style={{
                  borderColor: 'rgba(256, 256, 256, .3)',
                  borderWidth: '5px',
                  color: '#fff',
                }}
              >
                Welcome To VS HEALTH CARE
              </h5>
              <h1 className="display-1 mb-md-4" style={{ color: '#fff' }}>
                Best Healthcare Solution In Your City
              </h1>
              <div className="pt-2">
                <Link
                  className="btn btn-light rounded-pill py-md-3 px-md-5 mx-2"
                  to="/team"
                >
                  Find Doctor
                </Link>
                <Link
                  to="/appointment"
                  className="btn btn-outline-light rounded-pill py-md-3 px-md-5 mx-2"
                >
                  Appointment
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <About />
      <Services />
      <div className="container-fluid py-5">
        <div className="container">
          <div className="text-center mx-auto mb-5" style={{ maxWidth: 500 }}>
            <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">
              Our Doctors
            </h5>
            <h1 className="display-4">Qualified Healthcare Professionals</h1>
          </div>
          <Slider {...settings}>
            {teamMembers.map((member, index) => (
              <div className="team-item" key={index}>
                <div className="row g-0 bg-light rounded overflow-hidden">
                  <div className="col-12 col-sm-5 h-100">
                    <img
                      className="img-fluid h-100"
                      src={member.image}
                      alt={member.name}
                      style={{ objectFit: 'cover' }}
                    />
                  </div>
                  <div className="col-12 col-sm-7 h-100 d-flex flex-column">
                    <div className="mt-auto p-3">
                      <h3>{member.name}</h3>
                      <h6 className="fw-normal fst-italic text-primary mb-4">
                        {member.specialty}
                      </h6>
                      <p className="m-0">{member.description}</p>
                    </div>
                    <div className="d-flex mt-auto border-top p-3">
                      <a
                        className="btn btn-lg btn-lg-square me-3 moreLinkAnchor"
                        href={`/doctor/${member.routeName}`}
                        target="_blank"
                      >
                        More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Home;
